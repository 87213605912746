<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-form ref="frmFilter">
              <v-row class="mt-8">
                <v-col cols="12" md="3">
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    >Purchase Type</label
                  ><v-form ref="frmType">
                    <v-select
                      @change="clearVendor"
                      v-model="purchaseType"
                      :items="purchaseTypes"
                      item-text="type"
                      item-value="type"
                      color="rgba(0,0,0,.6)"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        border-radius-md
                        select-style
                        mb-0
                        mt-5
                      "
                      placeholder="Select a Purchase Type"
                      :rules="[(v) => !!v || 'Purchase Type is Required']"
                      outlined
                      single-line
                      height="48"
                    >
                    </v-select>
                  </v-form>
                </v-col>

                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>
                    Vendor</label
                  >
                  <v-autocomplete
                    v-model="vendor"
                    :items="vendors"
                    item-text="name"
                    item-value="id"
                    return-object
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    placeholder="Select a Vendor"
                    :rules="[(v) => !!v || 'Vendor is Required']"
                    outlined
                    single-line
                    height="48"
                    @change="getPo"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="purchaseDetails"
          class="table"
          show-expand
          hide-default-footer
          mobile-breakpoint="0"
        >
        </v-data-table>
      </v-card-text>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  data() {
    return {
      overlay: false,
      vendor: null,
      vendors: [],

      headers: [],
      purchaseDetails: [],
      purchaseTypes: [
        {
          type: "auction",
        },
        { type: "private" },
      ],
      purchaseType: "",
    };
  },
  methods: {
    async initialize() {
      this.overlay = true;
      this.vendors = await api.getVendors();
      this.overlay = false;
    },
    validatefrmType() {
      return this.$refs.frmType.validate();
    },
    clearVendor() {
      this.vendor = null;
      this.poNumber = null;
      this.poNumbers = [];
      //   this.selectedData = [];
      this.purchaseDetails = [];
    },
    async getPo() {
      if (this.validatefrmType()) {
        try {
          this.purchaseDetails = [];
          this.purchaseDetails = await api.getPo(
            this.vendor.id,
            this.purchaseType
          );
          console.log(this.purchaseDetails);
        } catch (error) {}
      } else {
        this.vendor = "";
      }
    },
  },
  created() {
    this.initialize();
  },
  showSuccessAlert(message) {
    this.$swal({
      title: "Success",
      text: message,
      type: "success",
      timer: 3000,
      icon: "success",
      showConfirmButton: false,
    });
  },

  showErrorAlert(message) {
    this.$swal({
      title: "Error",
      text: message,
      type: "error",
      timer: 3000,
      icon: "error",
      showConfirmButton: true,
    });
  },
};
</script>

<style></style>
