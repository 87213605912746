import axios from "axios";

export default {
  async getVendors() {
    return await axios
      .get("vendors")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  // async getSeason(vendor_id) {
  //   return await axios
  //     .get(`private-vendor-offer/vendor-offer-season-by-vendor-id/${vendor_id}`)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       throw new Error(err.response.data.message);
  //     });
  // },

  // async getOffers(vendor_id, season_id) {
  //   return await axios
  //     .get(
  //       `private-vendor-offer/get-offers-by-vendor-and-season?season_id=${season_id}&vendor_id=${vendor_id}`
  //     )
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       throw new Error(err.response.data.message);
  //     });
  // },
  async getConfirmedBid(vendor_id, season_id, offer_no) {
    return await axios
      .get(`/private-vendor-bid/confirmed/list?vendor_id=${vendor_id}&season_id=${season_id}&offer_no=${offer_no}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async addCompanyWareHouse(item) {
    return axios
      .post(`/purchase-private/add-company-ware-house`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getWareHouse() {
    return await axios
      .get(`/company-warehouse`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getGst() {
    return await axios
      .get(`/purchase-private/gst/view`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveGst(gst) {
    return await axios
      .post("/purchase-private/gst/update/gst", gst)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPo(vendor_id, type) {
    return await axios
      .get(`purchase-private/po/opened/by-vendor/${vendor_id}?purchase_type=${type}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getPoDetails(po_number, type) {
    return axios
      .get(`purchase-auction/purchase/details/${encodeURIComponent(po_number)}?purchase_type=${type}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
